import useAnalyticsUser from 'hooks/useAnalyticsUser'
import useSentryUser from 'hooks/useSentryUser'
import { useInitializeUserRegisterCountry } from 'hooks/useUserRegisterCountry'
import { useInitializeCreditBonusRestrictedGames, useInitializeFiatCurrencyConversionRateInUSD } from 'state/app/hooks'
import {
  useActivatedBonusesUpdater,
  useOnBonusFinishListener,
  useOnBoostWagerBonusListener,
  useUpdateUnwatchedNewBonus,
} from 'state/bonus/hooks'
import { useDailyCheckinWagerProcessSyncUp } from 'state/bonus/hooks/dailyReward'
import { useCashbackUpdater } from 'state/cashback/hooks'
import { useListenPrivateNotification, useListenSystemNotification } from 'state/notification/hooks'
import {
  useInitializeUserFavoriteGames,
  useInitializeWageringBonusGames,
  usePollUserBalances,
  useUpdateUserToken,
  useUserProfileUpdater,
} from 'state/profile/hooks'
import { usePollPendingTransaction } from 'state/transaction/hooks'

const UserBackgroundService = () => {
  usePollUserBalances()
  usePollPendingTransaction()
  useUserProfileUpdater()
  useActivatedBonusesUpdater()
  useListenPrivateNotification()
  useListenSystemNotification()
  useUpdateUserToken()
  useSentryUser()
  useAnalyticsUser()
  useCashbackUpdater()
  useInitializeWageringBonusGames()
  useInitializeUserFavoriteGames()
  useUpdateUnwatchedNewBonus()
  useOnBonusFinishListener()
  useOnBoostWagerBonusListener()
  useInitializeFiatCurrencyConversionRateInUSD()
  useDailyCheckinWagerProcessSyncUp()
  useInitializeUserRegisterCountry()
  useInitializeCreditBonusRestrictedGames()
  return null
}

export default UserBackgroundService
