import * as Sentry from '@sentry/nextjs'
import { useImmutableValueReference } from 'hooks/useImmutableValueReference'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useEffect } from 'react'
import { AuthRefreshHelper } from 'services/AuthRefreshHelper'
import { useAppDispatch } from 'state'
import { setIsSigned } from 'state/app/actions'
import { useAuth, useAuthRefresh } from 'state/auth/hooks'

const AuthenticationProvider = ({ children }) => {
  const { isSigned, hasSession, username, uid, deviceUid } = useAuth()
  const isSignedRef = useImmutableValueReference(isSigned)

  const { onRefreshFailed, refresh } = useAuthRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSigned && !hasSession) dispatch(setIsSigned({ isSigned: false }))
  }, [hasSession, isSigned])

  useIsomorphicEffect(() => {
    if (!hasSession) {
      Sentry.setUser(null)
      return
    }

    AuthRefreshHelper.init({
      username,
      uid,
      deviceUid,
      onRefreshFailed,
    })

    Sentry.setUser({
      username,
      deviceUid,
    })

    if (!isSignedRef.current) {
      refresh()
    }
  }, [hasSession])

  return isSigned && children
}

export default AuthenticationProvider

